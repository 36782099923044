import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ConversionProvider from 'providers/ConversionProvider';

const Switcher = lazy(() => import('components/Swicher'));
const ThankPage = lazy(() => import('components/ThankPage'));

const App = () => (
    <div className="App">
        <BrowserRouter>
            <ConversionProvider>
                <Routes>
                    <Route path="quiz/:quizId" element={<Switcher />} />
                    <Route path="thank-page/:quizId" element={<ThankPage />} />
                </Routes>
            </ConversionProvider>
        </BrowserRouter>
    </div>
);

export default App;
