import { PropsWithChildren, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';

const ConversionProvider = (props: PropsWithChildren) => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let _fbc = Cookies.get('_fbc');

        if (!_fbc) {
            const fbclid = searchParams.get('fbclid');

            if (!fbclid) {
                return;
            }

            let fbVerson = 0;
            const timestamp = Date.now();
            const domain = window.location.hostname;

            if (domain.startsWith('www.')) {
                fbVerson = 2;
            } else if (domain.includes('.')) {
                fbVerson = 1;
            } else {
                fbVerson = 0;
            }

            _fbc = `fb.${fbVerson}.${timestamp}.${fbclid}`;
            Cookies.set('_fbc', _fbc);
        }
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment, react/destructuring-assignment, react/react-in-jsx-scope
    return <>{props.children}</>;
};

export default ConversionProvider;
